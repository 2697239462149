import React from "react";
import { useUtils } from "../../../services/utilsProvider";
import FormAbout from "./FormAbout";

const EditAbout = ({ open, setOpen, About }) => {
  const { notify } = useUtils();

  const handleSuccess = () => {
    notify("A Text has been Updated successfully.");
    setOpen(!open);
  };
  return (
    <>
      <FormAbout
        open={open}
        setOpen={setOpen}
        About={About}
        handleSuccess={handleSuccess}
      />
    </>
  );
};

export default EditAbout;
