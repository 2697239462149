import styled, { css } from "styled-components";
import Label from "./InputLabel";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";

const Wrapper = styled.div`
  margin-bottom: 40px;
  ${(props) =>
    props.noSpace &&
    css`
      margin-bottom: 0;
    `}
  .inputWrap {
    position: relative;
    > span {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translate(0, -50%);
      cursor: pointer;
      fill: ${(props) => props.theme.color.dark};
      line-height: 0;
    }
  }

  p.msg {
    color: ${(props) => props.theme.color.grayText};
    padding-left: 9px;
    margin-top: 5px;
    margin-bottom: 0;
  }
  &.error p.msg {
    color: ${(props) => props.theme.color.red};
  }
  .MuiInputBase-root {
    border-radius: 12px;
    height: 60px;
    background-color: ${(p) => p.theme.color.white};
    font-family: "Cairo", sans-serif;
    font-size: 20px;
    font-weight: 600;
    fieldset {
      border-color: ${(p) => p.theme.color.gray300};
      &.MuiOutlinedInput-notchedOutline {
        border-color: ${(p) => p.theme.color.gray300};
      }
    }
    &.Mui-focused {
      fieldset {
        border-color: ${(p) => p.theme.color.primary};
      }
    }
  }
`;

function InputDate({
  label,
  name,
  value,
  id,
  placeholder = "",
  type = "date",
  msg = null,
  error = false,
  icon = null,
  size = "large",
  onDateChange,
  pickerProps,
  typeDate,
  ...props
}) {
  const onChange = (date) => {
    onDateChange(date);
  };

  const PickerComponent = type === "date" ? DatePicker : DateTimePicker;

  return (
    <Wrapper {...props} className={`${error ? "error" : ""}`}>
      <Label>{label}</Label>
      <div className="inputWrap">
        <PickerComponent
          value={value}
          onChange={onChange}
          {...pickerProps}
          format={type === "date" ? "dd/MM/yyyy" : "dd/MM/yyyy hh:mm a"}
          slotProps={{
            textField: { size: "medium", style: { width: "100%" } },
          }}
        />
      </div>
      {msg && <p className="msg">{msg}</p>}
    </Wrapper>
  );
}

export default InputDate;
