import { memo, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Icon,
  Select,
  TextField,
  Type,
} from "../../ui";
import { useNavigate, useParams } from "react-router-dom";
import fields from "./fields";
import { useEmployees } from "./provider";
import { objByStr } from "../../utilities/functions";
import Module from "../../ui/Module";
import FileField from "../../ui/FileField";
import Dropdown from "../../ui/Dropdown";
import CompensationsForm from "../employeeCompensations/CompensationsForm";
import TableContainer from "../../ui/TableContainer";
import { useEmployeeCompensations } from "../employeeCompensations/provider";
import { useUtils } from "../../services/utilsProvider";
import DeductionsForm from "../employeeDeductions/DeductionsForm";
import { useEmployeeDeduction } from "../employeeDeductions/provider";
import DocumentForm from "../employeeDocument/DocumentForm";
import { useDepartments } from "../departments/provider";
import Label from "../../ui/InputLabel";
import TextArea from "../../ui/TextArea";
import Modal from "../../ui/Modal";
import EditAbout from "./AboutEmployee/EditAbout";
import AddAbout from "./AboutEmployee/AddAbout";

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
  }
`;

const Grid2 = styled.div`
  max-width: 1310px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;

const Grid3 = styled.div`
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 32px;
  row-gap: 0;
`;

const Grid4 = styled.div`
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 0;
`;

const FileLabel = styled.label`
  ${(props) =>
    !props.document &&
    css`
      max-width: 50%;
    `}
  padding: 14px;
  border-radius: 15px;
  background-color: ${(props) =>
    props.theme.color[props.edit ? "primary" : "gray50"]};
  display: flex;
  align-items: center;
  line-height: 0;
  &:hover {
    border-color: ${(props) => props.theme.color.primary};
  }
  &:active {
    border-width: 2px;
    background-color: ${(props) => props.theme.color.gray100};
  }
  ${(props) =>
    props.edit
      ? css`
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          line-height: 0;
          p {
            margin-bottom: 0 !important;
          }
          &:hover,
          &:active {
            background-color: ${(props) => props.theme.color.primary};
          }
          svg {
            fill: white;
            width: 24px;
          }
        `
      : css`
          border: 1px dashed ${(props) => props.theme.color.gray};
        `}
  img {
    height: 54px;
  }
  .custom-file-input {
    visibility: hidden;
    max-width: 0;
  }
  .info {
    margin-left: 20px;
    cursor: pointer;
    p {
      margin: 0;
      margin-bottom: 24px;
      font-size: 16px;
      font-weight: 600;
      font-family: ${(props) => props.theme.fonts.openSans};
    }
    span {
      color: ${(props) => props.theme.color.grayLight};
      font-size: 14px;
    }
  }
  &.doc-preview {
    background-color: white;
    position: relative;
    .close {
      position: absolute;
      top: -10px;
      right: -10px;
      cursor: pointer;
      svg {
        width: 24px;
        height: 24px;
        fill: #ff4a55;
      }
    }
    .icon {
      cursor: pointer;
      padding: 18px;
      background-color: #f6eeff;
      border-radius: 15px;
      svg {
        width: 18px;
      }
    }
  }
  ${(props) =>
    props.document &&
    css`
      .info {
        text-decoration: none;
        color: ${(props) => props.theme.color.dark};
        p {
          margin-bottom: 10px;
          line-height: 18px;
          inline-size: 230px;
          overflow-wrap: break-word;
        }
      }
    `}
`;

const Wrap = styled.div`
  margin-left: 20px;
  .preview {
    width: 255px;
    min-height: 180px;
    background-color: ${(props) => props.theme.color.gray200};
    background-size: cover;
    border-radius: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    img {
      width: 100%;
      margin: 0;
      display: block;
      border: ${(props) => props.theme.color.grayBg} 1px solid;
    }
  }
`;
function ViewEmployee() {
  const params = useParams();
  const navigate = useNavigate();
  const { notify } = useUtils();

  const [selectedFile, setSelectedFile] = useState();
  const hiringType = [
    { id: 1, name: "OnPremise" },
    { id: 2, name: "Remotely" },
  ];
  const statusType = [
    { id: 1, name: "Active" },
    { id: 2, name: "Inactive" },
  ];
  const [compensation, setCompensation] = useState();
  const [deduction, setDeduction] = useState();

  const [message, setMessage] = useState();
  const [updated, setUpdated] = useState(true);
  const [dupdated, setDUpdated] = useState(true);
  const [showAbout, setShowAbout] = useState(false);
  const [reload, setReload] = useState(false);

  const {
    state: { selected, currency },
    fetchOne,
    fetchCurrency,
    deleteFile,
    setAbout,
  } = useEmployees();

  useEffect(() => {
    fetchCurrency();
  }, []);

  const getEnum = (name) => {
    if (name === "currencyId") return currency;
    switch (name) {
      case "genderId":
        return [
          { id: 1, name: "Female" },
          { id: 2, name: "Male" },
        ];
        break;
      case "attendanceTypeId":
        return [
          { id: 1, name: "Full Time" },
          { id: 2, name: "Part Time" },
        ];
        break;
      case "contractTypeId":
        return [
          { id: 1, name: "Permanent" },
          { id: 2, name: "Temporary" },
        ];
        break;
      default:
        return [];
    }
  };

  // const {
  //   state: { list: departments, status },
  //   fetchList: fetchDepartments,
  // } = useDepartments();

  // useEffect(() => {
  //   fetchDepartments(params.id);
  // }, [fetchDepartments]);

  // console.log(departments, "departments");

  const {
    state: { list: compensations, namesList: compensationNameList },
    remove: removeCompensation,
    fetchList,
    fetchNameList,
    dispatch,
  } = useEmployeeCompensations();

  const {
    state: { list: deductions, namesList: deductionsNameList },
    remove: removeDeduction,
    fetchList: fetchDeductions,
    fetchNameList: fetchDeductionsNameList,
    dispatch: dispatchDeduction,
  } = useEmployeeDeduction();

  const compensationsCols = [
    { name: "amount", fn: "currency", label: "Amount" },
    { name: "notes", label: "Notes" },
  ];

  const deductionsCols = [
    { name: "amount", fn: "currency", label: "Amount" },
    { name: "notes", label: "Notes" },
  ];

  const handleCompensationDelete = async (id) => {
    try {
      await removeCompensation(id);
      notify("A compensation has been deleted successfull.");
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeductionsDelete = async (id) => {
    try {
      await removeDeduction(id);
      notify("A deduction has been deleted successfull.");
    } catch (error) {
      console.log(error);
    }
  };

  const handleCompensationEdit = async (id) => {
    try {
      setCompensation(id);
      setMessage("A compensation has been edited successfull.");
      setShow(true);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeductionEdit = async (id) => {
    try {
      setDeduction(id);
      setMessage("A deduction has been edited successfull.");
      setShow(true);
    } catch (error) {
      console.log(error);
    }
  };

  // const {
  //   state: { selected: company },
  //   fetchOne: fetchCompany,
  // } = useCompanies();

  useEffect(() => {
    fetchOne(params.id);
    fetchList(params.id);
    fetchDeductions(params.id);
    return () => dispatch({ type: "set_selected", payload: null });
  }, [selectedFile, dupdated, updated, params.id, fetchOne, reload]);

  const handleDeleteFile = async (id) => {
    try {
      await deleteFile(id);
      notify("A File has been Deleted successfull.");
      await fetchOne(params.id);
      selectedFile(false);
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   if (selected) fetchCompany(selected.companyId);
  // }, [fetchCompany, selected]);

  // if (!company) return;
  // useEffect(() => {
  //   if (selected) {
  //     selected.contract != 0 &&
  //       setSelectedFile({
  //         name: selected.contract,
  //         size: "10",
  //         url: selected.contractUrl,
  //       });
  //   }
  // }, [selected]);

  const handleDeleteAbout = async () => {
    try {
      await setAbout(params?.id, { about: "" });
      notify("About has been deleted successfully.");
      await fetchOne(params?.id);
    } catch (error) {
      console.log(error, "error");
    }
  };
  const [show, setShow] = useState();

  const [showAddAbout, setShowAddAbout] = useState(false);
  const [showEditAbout, setShowEditAbout] = useState(false);
  if (!selected) return;
  if (!deductions) return;
  if (!compensations) return;
  return (
    <div>
      <Grid>
        <h2 style={{ marginTop: "0" }}>Employee Details</h2>
        <div style={{ cursor: "pointer" }}>
          {/* <Button
            style={{ paddingRight: "40px", paddingLeft: "40px" }}
            className="small"
            bg="red"
          >
            Deactivate
          </Button> */}
          <Button
            style={{
              paddingRight: "40px",
              paddingLeft: "40px",
              marginLeft: "30px",
            }}
            iconName="invoice"
            className="small"
            onClick={() =>
              navigate(`/employees/bank/${params.id}`, { state: selected })
            }
          >
            Employee Bank
          </Button>
          <Button
            style={{
              paddingRight: "40px",
              paddingLeft: "40px",
              marginLeft: "30px",
            }}
            iconName="pen"
            className="small"
            onClick={() => navigate(`/employees/edit/${params.id}`)}
          >
            Edit
          </Button>
        </div>
      </Grid>
      <Card>
        <Grid2>
          <div>
            <Grid2>
              {fields
                .filter((f) => !f.viewDisabled)
                .map((f) => {
                  const name = f.viewName || f.name;
                  let value =
                    f.type === "date"
                      ? new Date(selected[name]).toLocaleDateString("fr-FR")
                      : objByStr(selected, name);
                  if (f.fn && value) value = f.fn(value);
                  if (f.editOnly == true) return;
                  if (f.enum) {
                    <TextField
                      inputStyle={{ height: "48px", width: "170px" }}
                      label="Status"
                      name="status"
                      inputProps={{
                        disabled: true,
                      }}
                    />;
                  }
                  return (
                    <TextField
                      key={name}
                      label={f.label}
                      inputProps={
                        !f.enum
                          ? {
                              value: value || "",
                              readOnly: true,
                              disabled: true,
                            }
                          : {
                              value: getEnum(f.enum)
                                .filter((e) => e.id == selected[f.enum])
                                .map((e) => {
                                  return e.name;
                                }),

                              readOnly: true,
                              disabled: true,
                            }
                      }
                    />
                  );
                })}
              <TextField
                inputStyle={{ height: "48px", width: "170px" }}
                label="Hiring Type"
                name="hiringTypeId"
                inputProps={{
                  value: hiringType
                    .filter((e) => e.id == selected.hiringTypeId)
                    .map((e) => {
                      return e.name;
                    }),
                  disabled: true,
                }}
              />

              <TextField
                inputStyle={{ height: "48px", width: "170px" }}
                label="Job Level"
                name="jobLevel"
                inputProps={{
                  value: selected.jobLevel,
                  disabled: true,
                }}
              />

              <TextField
                inputStyle={{ height: "48px", width: "170px" }}
                label="Status"
                name="status"
                inputProps={{
                  value: statusType
                    .filter((e) => e.id == selected?.statusId)
                    .map((e) => {
                      return e.name;
                    }),
                  disabled: true,
                }}
              />

              <TextField
                label="Contract Type"
                name="contractType"
                inputProps={{
                  value: getEnum("contractTypeId")
                    .filter((e) => e.id == selected?.contractTypeId)
                    .map((e) => e.name),
                  disabled: true,
                }}
              />
              <TextField
                label="Direct Manager"
                name="managerName"
                inputProps={{
                  value: selected.directManagerInfo?.managerName,
                  disabled: true,
                }}
              />
              {/* <TextField
            label="Department"
            name="department"
            inputProps={{
              value: selected?.departmentInfo?.departmentName,
              disabled: true,
            }}
          /> */}
              <TextField
                label="Max Annual Leave"
                name="maxAnnualLeave"
                inputProps={{
                  value: selected?.maxAnnualLeave,
                  disabled: true,
                }}
              />

              <TextField
                label="Max Sick Leave"
                name="maxSickLeave"
                inputProps={{
                  value: selected?.maxSickLeave,
                  disabled: true,
                }}
              />

              <TextField
                label="Remaining  Leave"
                name="remainingAnnuals"
                inputProps={{
                  value: selected?.remainingAnnuals,
                  disabled: true,
                }}
              />

              <TextField
                label="Remaining Sickness Leave"
                name="remainingSickness"
                inputProps={{
                  value: selected?.remainingSickness,
                  disabled: true,
                }}
              />

              <TextField
                label="Daily Hours"
                name="dailyHours"
                inputProps={{
                  value: selected?.dailyHours,
                  disabled: true,
                }}
              />
            </Grid2>
            <Grid2 style={{ marginTop: "20px" }}>
              {selected?.contract && (
                <FileField
                  label="Contract"
                  buttonLabel={"Employee Contract"}
                  document
                  readOnly
                  documentFile={{
                    name: selected?.contract,
                    url: selected?.contractUrl,
                  }}
                  disabled
                />
              )}
              {/* {selected.documentList?.length && (
            <FileField
              label="CV"
              buttonLabel={"Employee CV"}
              readOnly
              document
              documentFile={{
                name: selected.documentList[0]?.documentTitle,
                url: selected.documentList[0]?.documentUrl,
              }}
              disabled
            />
          )} */}
            </Grid2>
          </div>
          <div>
            <Wrap>
              <Label>Employee photo</Label>
              <div className="preview">
                {Boolean(selected.photoUrl) && (
                  <img src={selected.photoUrl} alt="preview uploaded file" />
                )}
              </div>
            </Wrap>
          </div>
        </Grid2>
      </Card>
      <Divider h={20} />
      <Flex jc="space-between" ai="center">
        <h2>About Employee</h2>

        {selected?.about ? (
          <Flex>
            <Button
              iconName="Disk"
              className="small"
              style={{ marginRight: 5 }}
              onClick={() => {
                setShowEditAbout(!showEditAbout);
              }}
            >
              Edit
            </Button>
            <Button
              onClick={() => {
                if (window.confirm("Are you sure?")) handleDeleteAbout();
              }}
              iconName="trash"
              className="small"
              bg="red"
            >
              Delete
            </Button>
          </Flex>
        ) : (
          <Button
            iconName="invoice"
            className="small"
            style={{ marginRight: 5 }}
            onClick={() => {
              setShowAddAbout(!showAddAbout);
            }}
          >
            Add
          </Button>
        )}
      </Flex>{" "}
      {selected.about ? (
        <TextArea
          label=""
          rows="4"
          cols="2"
          value={selected?.about ? selected?.about : ""}
          inputProps={{
            disabled: true,
          }}
        />
      ) : null}
      <Grid3>
        <h2>Documents</h2>
        <DocumentForm
          empId={params.id}
          compensation={compensations
            .filter((c) => {
              return c.id == compensation;
            })
            .map((c) => {
              return c;
            })}
          setCompensation={setCompensation}
          setUpdated={setUpdated}
          updated={updated}
          setSelectedFile={setSelectedFile}
        />
      </Grid3>
      {selected?.documentList?.length > 0 && (
        <Card style={{ display: "flex", flexWrap: "wrap" }}>
          {selected.documentList &&
            selected.documentList.map((d) => {
              return (
                <div style={{ marginTop: "10px" }}>
                  <FileLabel
                    document={d}
                    className="doc-preview"
                    style={{ margin: "0 20px" }}
                  >
                    {
                      <span
                        className="close"
                        onClick={() => handleDeleteFile(d.id)}
                      >
                        <Icon name="circleXMark" />
                      </span>
                    }
                    <a
                      className="icon"
                      href={d.documentUrl}
                      download={d.documentUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Icon name="document" />
                    </a>
                    <a
                      className="info"
                      href={d.documentUrl}
                      download={d.documentUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p>{d.documentTitle}</p>
                      <span>Click to Download</span>
                    </a>
                  </FileLabel>
                </div>
              );
            })}
        </Card>
      )}
      <Divider h={20} />
      {selected?.about ? (
        <Modal
          isOpen={showEditAbout}
          onClose={() => {
            setShowEditAbout(!showEditAbout);
          }}
        >
          <EditAbout
            open={showEditAbout}
            setOpen={setShowEditAbout}
            About={selected?.about}
          />
          {/**About Section */}
        </Modal>
      ) : (
        <Modal
          isOpen={showAddAbout}
          onClose={() => {
            setShowAddAbout(!showAddAbout);
          }}
        >
          <AddAbout open={showAddAbout} setOpen={setShowAddAbout} />
        </Modal>
      )}
      <Grid3>
        <h2>Compensations</h2>

        <CompensationsForm
          empId={params.id}
          compensation={compensations
            .filter((c) => {
              return c.id == compensation;
            })
            .map((c) => {
              return c;
            })}
          setCompensation={setCompensation}
          setUpdated={setUpdated}
          updated={updated}
          handleSuccess={() =>
            notify(message ?? "A compensation has been added successfully")
          }
        />
      </Grid3>
      <TableContainer
        empty={compensations?.length === 0}
        aria-label="Compensations table"
        cols={compensationsCols}
        data={compensations}
        moreOptions={{
          edit: handleCompensationEdit,
          delete: handleCompensationDelete,
        }}
      />
      <Divider h={20} />
      <Grid3>
        <h2>Deductions</h2>

        <DeductionsForm
          empId={params.id}
          compensation={deductions
            .filter((c) => {
              return c.id == deduction;
            })
            .map((c) => {
              return c;
            })}
          setCompensation={setDeduction}
          setUpdated={setDUpdated}
          updated={dupdated}
          handleSuccess={() =>
            notify(message ?? "A Deduction has been added successfully")
          }
        />
      </Grid3>
      <TableContainer
        empty={deductions?.length === 0}
        aria-label="deductions table"
        cols={deductionsCols}
        data={deductions}
        moreOptions={{
          edit: handleDeductionEdit,
          delete: handleDeductionsDelete,
        }}
      />
    </div>
  );
}

export default memo(ViewEmployee);
