import countries from "../../data/countries.json";
import { addDays, currency, subtractYears } from "../../utilities/functions";
import { emptyDate, emptyStringToNull } from "../../utilities/yupSchemaCreator";
import * as Yup from "yup";
const fields = [
  {
    name: "firstName",
    type: "text",
    label: "First Name",
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "lastName",
    type: "text",
    label: "Last Name",
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "jobTitle",
    type: "text",
    label: "Job Title",
    validationType: "string",
  },
  {
    name: "basicSalary",
    type: "number",
    label: "Basic Salary",
    validationType: "number",
    validations: [
      {
        type: "nullable",
        params: [true],
      },
      {
        type: "transform",
        params: [emptyStringToNull],
      },
      {
        type: "min",
        params: [1, "Basic Salary must be greater than 0"],
      },
    ],
  },

  {
    name: "currencyId",
    type: "number",
    resourceName: "currencyList",
    enum: "currencyId",
    data: [],
    mapData: { value: "id", label: "shortName" },
    label: "Currency",
    validationType: "number",
    validations: [
      {
        type: "nullable",
        params: [true],
      },
      {
        type: "transform",
        params: [emptyStringToNull],
      },
    ],
  },

  {
    name: "contractTypeId",
    data: [
      { id: 1, name: "Permanent" },
      { id: 2, name: "Temporary" },
    ],
    mapData: { value: "id", label: "name" },
    label: "Contract Type",
    enum: "contractTypeId",
    editOnly: true,
    validationType: "number",
    required: true,
    validations: [
      {
        type: "required",
        params: ["Required."],
      },
      {
        type: "nullable",
        params: [true],
      },
      {
        type: "transform",
        params: [emptyStringToNull],
      },
    ],
  },
  {
    name: "attendanceTypeId",
    data: [
      { id: 1, name: "Full Time" },
      { id: 2, name: "Part Time" },
    ],
    mapData: { value: "id", label: "name" },
    label: "Attendance Type",
    enum: "attendanceTypeId",
    validationType: "number",

    required: true,
    validations: [
      {
        type: "required",
        params: ["Required."],
      },
      {
        type: "nullable",
        params: [true],
      },
      {
        type: "transform",
        params: [emptyStringToNull],
      },
    ],
  },
  {
    name: "nationality",
    type: "text",
    data: countries,
    mapData: { value: "name", label: "name" },
    label: "Nationality",
    validationType: "string",
  },
  {
    name: "genderId",
    data: [
      { id: 1, name: "Female" },
      { id: 2, name: "Male" },
    ],
    mapData: { value: "id", label: "name" },
    label: "Gender",
    enum: "genderId",
    validationType: "number",
    required: true,
    validations: [
      {
        type: "required",
        params: ["Required."],
      },
      {
        type: "nullable",
        params: [true],
      },
      {
        type: "transform",
        params: [emptyStringToNull],
      },
    ],
  },
  {
    name: "birthDate",
    type: "date",
    label: "Birth Date",
    inputProps: { max: subtractYears(15).toISOString().split("T")[0] },
    // validations: [
    //   {
    //     type: "transform",
    //     params: [emptyDate],
    //   },
    // ],
  },
  {
    name: "mobile",
    type: "text",
    label: "Mobile",
    validationType: "string",
    validations: [
      {
        type: "matches",
        params: [/^[0-9]+$/, "must be a number"],
      },
    ],
  },
  {
    name: "email",
    type: "email",
    label: "Email address",
    validationType: "string",
    validations: [
      {
        type: "email",
        params: ["please enter a valid email"],
      },
    ],
  },
  {
    name: "hiringTypeId",
    type: "number",
    data: [
      { id: 1, name: "OnPremise" },
      { id: 2, name: "Remotely" },
    ],
    mapData: { value: "id", label: "name" },
    label: "Hiring Type",
    validationType: "number",
    editOnly: true,
    validations: [
      {
        type: "required",
        params: ["Required."],
      },
      {
        type: "nullable",
        params: [true],
      },
      {
        type: "transform",
        params: [emptyStringToNull],
      },
    ],
  },
  {
    name: "hiringDate",
    type: "date",
    label: "Hiring Date",
    validationType: "string",
    //inputProps: { min: addDays(1).toISOString().split("T")[0] },
    //inputProps: { max: subtractYears(15).toISOString().split("T")[0] },
    // validations: [
    //   {
    //     type: "transform",
    //     params: [emptyDate],
    //   },
    // ],
  },
  {
    name: "machineEmployeeID",
    type: "text",
    label: "Machine Employee ID",
    validationType: "string",
    validations: [
      // {
      //   type: "required",
      //   params: ["Required."],
      // },
      {
        type: "when",
        params: [
          "hiringTypeId",
          {
            is: 1,
            then: Yup.string().required("required"),
          },
        ],
      },
      {
        type: "nullable",
        params: [true],
      },
    ],
  },
  {
    name: "jobLevel",
    type: "number",
    data: [
      { id: 1, name: "1" },
      { id: 2, name: "2" },
      { id: 3, name: "3" },
      { id: 4, name: "4" },
      { id: 5, name: "5" },
      { id: 6, name: "6" },
    ],
    mapData: { value: "id", label: "name" },
    label: "Job Level",
    validationType: "number",
    editOnly: true,
    validations: [
      {
        type: "required",
        params: ["Required."],
      },
      {
        type: "nullable",
        params: [true],
      },
      {
        type: "transform",
        params: [emptyStringToNull],
      },
    ],
  },
  {
    name: "jobLocation",
    type: "text",
    label: "Job Location",
    validationType: "string",
    validations: [
      {
        type: "when",
        params: [
          "hiringTypeId",
          {
            is: 2,
            then: Yup.string().required("required"),
          },
        ],
      },
    ],
  },
  {
    name: "statusId",
    type: "number",
    label: "Status",
    data: [
      { id: 1, name: "Active" },
      { id: 2, name: "Inactive" },
    ],
    mapData: { value: "id", label: "name" },
    editOnly: true,
    validationType: "number",
    validations: [
      {
        type: "nullable",
        params: [true],
      },
      {
        type: "transform",
        params: [emptyStringToNull],
      },
    ],
  },
  {
    name: "companyId",
    viewName: "companyInfo.companyName",
    type: "number",
    resourceName: "companies",
    data: [],
    mapData: { value: "id", label: "businessName" },
    label: "Company",
    validationType: "number",
    required: true,
    validations: [
      {
        type: "required",
        params: ["Required."],
      },
      {
        type: "nullable",
        params: [true],
      },
      {
        type: "transform",
        params: [emptyStringToNull],
      },
    ],
  },
  {
    name: "departmentId",
    viewName: "departmentInfo.departmentName",
    type: "number",
    resourceName: "departments",
    data: [],
    mapData: { value: "id", label: "departmentName" },
    label: "Department",
    validationType: "number",
    msg: "Select a company first.",

    required: true,
    validations: [
      {
        type: "required",
        params: ["Required."],
      },
      {
        type: "nullable",
        params: [true],
      },
      {
        type: "transform",
        params: [emptyStringToNull],
      },
    ],
  },

  {
    name: "attendancePatternId",
    viewName: "attendancePatternInfo.attendancePatternName",
    type: "number",
    resourceName: "attendancePatterns",
    data: [],
    mapData: { value: "id", label: "attendancePatternName" },
    label: "Attendance Pattern",
    msg: "Select a company first.",
    validationType: "number",
    validations: [
      {
        type: "nullable",
        params: [true],
      },
      {
        type: "transform",
        params: [emptyStringToNull],
      },
    ],
  },

  {
    name: "userInfo.roles",
    fn: (v) => v.join(", "),
    viewOnly: true,
    label: "Role",
  },
  {
    name: "userInfo.username",
    viewOnly: true,
    label: "Username",
  },
];

export default fields;
