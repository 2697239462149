import React from "react";
import { useUtils } from "../../../../services/utilsProvider";
import FormLink from "./FormLink";

const AddLink = ({ open, setOpen }) => {
  const { notify } = useUtils();

  const handleSuccess = () => {
    notify("A Tag has been Added successfully.");
    setOpen(!open);
  };
  return (
    <>
      <h3 style={{ marginTop: "0" }}>Add Link </h3>
      <FormLink open={open} setOpen={setOpen} handleSuccess={handleSuccess} />
    </>
  );
};

export default AddLink;
