import React from "react";
import FormTag from "./FormTag";
import { useUtils } from "../../../../services/utilsProvider";

const AddTag = ({ open, setOpen }) => {
  const { notify } = useUtils();

  const handleSuccess = () => {
    notify("A Tag has been Added successfully.");
    setOpen(!open);
  };
  return (
    <>
      <h3 style={{ marginTop: "0" }}>Add Tag </h3>
      <FormTag open={open} setOpen={setOpen} handleSuccess={handleSuccess} />
    </>
  );
};

export default AddTag;
