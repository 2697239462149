import countries from "../../data/countries.json";
import { addDays, currency, subtractYears } from "../../utilities/functions";
import { emptyDate, emptyStringToNull } from "../../utilities/yupSchemaCreator";
import * as Yup from "yup";
const fields = [
  {
    name: "projectName",
    type: "text",
    label: "Project Name",
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },

  {
    name: "description",
    type: "text",
    label: "Description",
    longDescription: true,
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  // {
  //   name: "tags",
  //   type: "text",
  //   label: "Project Tags",
  //   validationType: "string",
  //   tagInput: true,
  //   validations: [
  //     {
  //       type: "required",
  //       params: ["this field is required"],
  //     },
  //   ],
  // },

  {
    name: "isActive",
    type: "number",
    label: "Status",
    switch: true,
    validationType: "boolean",
  },
];

export default fields;
