import React, { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Button, Card, Checkbox, TextField } from "../../ui";
import { useProject } from "./provider";
import { useCompanies } from "../companies/provider";
import ErrorsList from "../../ui/ErrorsList";
import fields from "./fields";
import FileField from "../../ui/FileField";
import Label from "../../ui/InputLabel";
import TextArea from "../../ui/TextArea";

import "./style/style.css";
import TagsComponent from "./Component/Tags/TagsComponent";
import Links from "./Component/Links/Links";
import ImagesComponent from "./Component/Images/ImagesComponent";
import Editor from "../news/Editor";

const schema = Yup.object().shape({
  projectName: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  image: Yup.string(),
  isActive: Yup.boolean(),
  logo: Yup.string(),
  companyId: Yup.number(),
});

const Grid = styled.div`
  display: grid;
  grid-template-columns: 300px 500px;
  gap: 32px;
  row-gap: 10px;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  button {
    float: right;
    margin-left: 20px;
    min-width: 180px;
  }
`;

const ProjectForm = ({ viewOnly, project = null, handleSuccess }) => {
  const [error, setError] = useState(null);

  const { cid, id } = useParams();
  let navigate = useNavigate();

  const [editorHtml, setEditorHtml] = useState(project?.content || null);

  const [preview, setPreview] = useState(project?.imageUrl || null);
  const [preview2, setPreview2] = useState(project?.logoUrl || null);
  const [editorDescription, setEditorDescription] = useState(
    project?.description || null
  );
  const {
    state: { selected },
    fetchOne: fetchCompany,
  } = useCompanies();
  const {
    state: { fileName, logo },
    // dispatch,
    _uploadImage,
    _uploadLogo,
    create,
    edit,
  } = useProject();

  const {
    register,
    handleSubmit,

    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    fetchCompany(cid);
  }, [fetchCompany, cid]);
  useEffect(() => setValue("content", editorHtml), [editorHtml, setEditorHtml]);
  useEffect(
    () => setValue("description", editorDescription),
    [editorDescription, setEditorDescription]
  );
  // useEffect(() => {
  //   if (project) {
  //     project.tags.split(";").map((t) => tags.push({ id: t, text: t }));
  //   }
  // }, []);
  // // useEffect(() => {
  //   setTextTags(tags.map((t) => t.text));
  // }, [tags]);
  useEffect(() => {
    setValue("companyId", cid);
  }, [cid]);
  const onSubmit = async (values) => {
    values.image = fileName;
    values.logo = logo;
    // values.tags = textTags.join(";").toString();
    if (!values?.image) {
      values.image = project?.image;
    }
    if (!values?.logo) {
      values.logo = project?.logo;
    }

    delete values.imageUrl;
    delete values.logoUrl;
    delete values.content;
    try {
      if (project) {
        edit(id, values);
      } else {
        create(values);
      }
      handleSuccess();
    } catch (error) {
      setError(error.errors.message);
    }
  };
  const handleImageChange = (event) => {
    setPreview(URL.createObjectURL(event.target.files[0]));
    _uploadImage(event.target.files[0]);
  };

  const handleLogoChange = (event) => {
    setPreview2(URL.createObjectURL(event.target.files[0]));
    _uploadLogo(event.target.files[0]);
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        {error && <ErrorsList error={error} />}
        <Card>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "80%",
            }}
          >
            <Grid>
              <TextField
                label="Company"
                value={selected?.businessName}
                style={{ marginBottom: 0 }}
                inputProps={{
                  value: selected?.businessName,
                  readOnly: true,
                  disabled: true,
                  style: { height: 60 },
                }}
              />

              {fields.map((f, i) => {
                if (f.viewOnly) return null;
                const inputProps = { ...f.inputProps };

                if (i === 0) inputProps.autoFocus = true;

                inputProps.type = f.type;
                if (f.switch) {
                  return (
                    <div style={{ display: "flex", marginTop: "50px" }}>
                      <Checkbox
                        id="statusId"
                        name="status"
                        inputProps={{
                          ...register(f.name),
                          defaultChecked: project ? project?.isActive : true,
                          readOnly: viewOnly,
                          disabled: viewOnly,
                        }}
                      />
                      <Label>Active</Label>
                    </div>
                  );
                } else if (f.longDescription) {
                  return (
                    <>
                      <FileField
                        id="image"
                        label="Project Image"
                        buttonLabel={project ? "Edit Image" : "Upload Image"}
                        preview={preview}
                        edit={Boolean(project)}
                        onChange={(e) => {
                          handleImageChange(e);
                        }}
                        inputProps={{
                          name: "image",
                          // ...register("image"),
                          accept: "image/jpng,image/png,image/jpg,image/jpeg",
                          readOnly: viewOnly,
                          disabled: viewOnly,
                        }}
                        readOnly={viewOnly}
                        disabled={viewOnly}
                      />
                      <div style={{ width: "500px" }}>
                        <Label>Description</Label>
                        <Editor
                          editorHtml={editorDescription}
                          setEditorHtml={setEditorDescription}
                          readOnly={viewOnly}
                        />
                      </div>
                      {/* <TextArea
                        key={i}
                        label={f.label}
                        name={f.name}
                        error={!!errors[f.name]}
                        msg={errors[f.name]?.message}
                        inputProps={{
                          ...register(f.name),
                          ...inputProps,
                          readOnly: viewOnly,
                          disabled: viewOnly,
                        }}
                        rows="6"
                      /> */}
                    </>
                  );
                } else {
                  return (
                    <TextField
                      key={i}
                      label={f.label}
                      name={f.name}
                      error={!!errors[f.name]}
                      msg={errors[f.name]?.message}
                      inputProps={{
                        ...register(f.name),
                        ...inputProps,
                        readOnly: viewOnly,
                        disabled: viewOnly,
                        defaultValue: project?.[f.name],
                      }}
                    />
                  );
                }
              })}
            </Grid>

            <FileField
              id="logo"
              label="Project Logo"
              buttonLabel={project ? "Edit Logo" : "Upload Logo"}
              preview={preview2}
              edit={Boolean(project)}
              onChange={(e) => {
                handleLogoChange(e);
              }}
              inputProps={{
                name: "image2",
                // ...register000("image"),
                accept: "image/jpng,image/png,image/jpg,image/jpeg",
              }}
              readOnly={viewOnly}
              disabled={viewOnly}
            />
          </div>
        </Card>
        {!viewOnly && (
          <ButtonWrapper>
            <Button
              type="submit"
              data-testid="save-button"
              iconName="Disk"
              className="small"
              loading={isSubmitting}
            >
              Save
            </Button>
            <Button
              type="button"
              className="outlined small"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          </ButtonWrapper>
        )}
      </form>

      {viewOnly && (
        <>
          <TagsComponent tags={project?.tags} />
          <Links links={project?.links} />
          <ImagesComponent Images={project?.files} />
        </>
      )}
    </>
  );
};

export default memo(ProjectForm);
