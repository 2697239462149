import React from "react";
import FormAbout from "./FormAbout";
import { useUtils } from "../../../services/utilsProvider";

const AddAbout = ({ open, setOpen }) => {
  const { notify } = useUtils();

  const handleSuccess = () => {
    notify("A Text has been Added successfully.");
    setOpen(!open);
  };
  return (
    <>
      <FormAbout open={open} setOpen={setOpen} handleSuccess={handleSuccess} />
    </>
  );
};

export default AddAbout;
