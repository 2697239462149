import React, { useState } from "react";
import FormTag from "./FormTag";
import { useUtils } from "../../../../services/utilsProvider";

const UpdateTag = ({ tag, open, setOpen }) => {
  const { notify } = useUtils();

  const handleSuccess = () => {
    notify("A Tag has been Updated successfully.");
    setOpen(!open);
  };
  return (
    <>
      <h3 style={{ marginTop: "0" }}>Update Tag </h3>
      <FormTag
        tag={tag}
        open={open}
        setOpen={setOpen}
        handleSuccess={handleSuccess}
      />
    </>
  );
};

export default UpdateTag;
