import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import fields from "./fields";
import ErrorsList from "../../../../ui/ErrorsList";
import { createYupSchema } from "../../../../utilities/yupSchemaCreator";
import { Button, Flex, Select, TextField } from "../../../../ui";
import TextArea from "../../../../ui/TextArea";
import { useProject } from "../../provider";
import { useParams } from "react-router-dom";

const yupSchema = fields.reduce(createYupSchema, {
  typeId: Yup.number(),
  caption: Yup.string(),
  url: Yup.string().required("this field is required"),
});
const schema = Yup.object().shape(yupSchema);

const FormLink = ({ link, handleSuccess, open, setOpen }) => {
  const [error, setError] = useState(null);
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { setLink, fetchOne } = useProject();
  const onChange = useCallback(
    (v, name) => {
      setValue(name, v.value);
    },
    [setValue]
  );
  const onSubmit = async (values) => {
    try {
      if (link) {
        await setLink(id, link?.id, values);
      } else {
        await setLink(id, 0, values);
      }
      fetchOne(id);
      handleSuccess();
    } catch (error) {
      setError(error.errors.message);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}
      {fields.map((f, i) => (
        <>
          {f.select && (
            <Select
              label={f.label}
              data={f.data}
              key={i}
              mapOptions={f.mapData}
              required={f.required}
              defaultValue={link?.typeId}
              onChange={onChange}
              error={!!errors[f.name]}
              msg={errors[f.name]?.message || f.msg}
              inputProps={{
                ...register(f.name),
              }}
            />
          )}
          {f.type === "text" && (
            <TextField
              key={i}
              label={f.label}
              name={f.name}
              error={!!errors[f.name]}
              msg={errors[f.name]?.message}
              style={{ width: "600px", margin: "15px 5px" }}
              inputProps={{
                ...register(f.name),
                defaultValue: link?.[f.name],
              }}
            />
          )}
          {f.type === "textarea" && (
            <TextArea
              key={i}
              label={f.label}
              name={f.name}
              error={!!errors[f.name]}
              msg={errors[f.name]?.message}
              style={{ width: "600px", margin: "15px 5px" }}
              inputProps={{
                ...register(f.name),
              }}
              defaultValue={link?.caption}
              rows={5}
              cols={2}
            />
          )}
        </>
      ))}
      <Flex jc="end" sx={{ m: 10 }}>
        <Button
          iconName="Disk"
          className="small"
          style={{ marginRight: 5 }}
          type="submit"
        >
          Save
        </Button>
        <Button
          type="button"
          className="outlined small"
          onClick={() => {
            setOpen(!open);
          }}
        >
          Cancel
        </Button>
      </Flex>
    </form>
  );
};

export default FormLink;
