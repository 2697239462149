import { Box } from "@mui/material";
import React, { useState } from "react";
import TextArea from "../../../ui/TextArea";
import { Button, Flex } from "../../../ui";
import { useParams } from "react-router-dom";
import { useEmployees } from "../provider";

const FormAbout = ({ About, open, setOpen, handleSuccess }) => {
  const params = useParams();
  const [AboutText, setAboutText] = useState(About ? About : "");

  const {
    state: { selected, currency },
    fetchOne,
    fetchCurrency,
    deleteFile,
    setAbout,
  } = useEmployees();
  const SetAboutClick = async () => {
    try {
      await setAbout(params?.id, { about: AboutText });
      handleSuccess();
      await fetchOne(params?.id);
    } catch (error) {
      console.log(error, "error");
    }
  };
  return (
    <>
      <Box>
        <TextArea
          onChange={(e) => {
            setAboutText(e.target.value);
          }}
          value={AboutText}
          style={{ width: 600 }}
          label={"About Employee"}
          rows={5}
          cols={2}
        />
      </Box>
      <Flex jc="end">
        <Button
          iconName="Disk"
          className="small"
          style={{ marginRight: 5 }}
          onClick={SetAboutClick}
          disabled={!AboutText}
        >
          Save
        </Button>
        <Button
          type="button"
          className="outlined small"
          onClick={() => {
            setOpen(!open);
          }}
        >
          Cancel
        </Button>
      </Flex>
    </>
  );
};

export default FormAbout;
