import React, { useEffect } from "react";
import ProjectForm from "./ProjectForm";
import { useProject } from "./provider";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Button, Flex, Icon } from "../../ui";
import { useUtils } from "../../services/utilsProvider";

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 0;
    svg {
      margin: 0 20px 0 10px;
    }
  }
`;

const ViewProject = () => {
  const { id } = useParams();
  const { cid } = useParams();
  const navigate = useNavigate();

  const { notify, alertUser } = useUtils();
  const {
    state: { selected },
    fetchOne,
    remove,
    dispatch,
  } = useProject();

  useEffect(() => {
    fetchOne(id);
  }, [id]);

  const handleDelete = async () => {
    try {
      await remove(id);
      notify("Changes saved successfully");
      navigate(-1);
    } catch (e) {
      if (e.status === 400) alertUser(e.errors);
      else if (e.status === 405)
        alertUser(
          `The department can’t be deleted because of dependent records`
        );
      console.log(e);
    }
  };

  if (!selected || selected.id !== Number(id)) return;
  return (
    <>
      <Grid>
        <h2 style={{ marginTop: "0" }}>Project Details </h2>
        <Flex>
          <Button
            iconName="Disk"
            className="small"
            style={{ margin: "0 5px" }}
            onClick={() => {
              navigate(`/projects/company/${cid}/edit/${id}`);
            }}
          >
            Edit
          </Button>
          <Button
            onClick={handleDelete}
            iconName="trash"
            className="small"
            style={{ margin: "0 5px" }}
            bg="red"
          >
            Delete
          </Button>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <span>Back</span>
            <Icon name="arrowLeft" />
          </div>
        </Flex>
      </Grid>
      <ProjectForm project={selected} viewOnly={true} />
    </>
  );
};

export default ViewProject;
