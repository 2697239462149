import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";

const Context = createContext();

export function useProject() {
  return useContext(Context);
}
const DEFAULT_PAGESIZE = 150;
const initState = {
  list: [],
  selected: null,
  filters: {
    pagesize: DEFAULT_PAGESIZE,
  },
  count: 0,
  page: 0,
  fileName: "",
  logo: "",
  status: "idle",
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_filters":
      return { ...state, filters: { ...state.filters, ...action.payload } };
    case "set_fileName":
      return { ...state, fileName: action.payload };
    case "set_logo":
      return { ...state, logo: action.payload };
    case "set_count":
      return { ...state, count: action.payload };
    case "status":
      return { ...state, status: action.payload };
    case "add":
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case "add_document":
      const newDocs = [{ ...action.payload }, ...state.documents];
      return { ...state, documents: newDocs };
    case "edit":
      const modified = (state.list || []).map((p) =>
        p.id === action.payload.id ? { ...p, ...action.payload } : p
      );
      return { ...state, list: modified };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "delete":
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function ProjectsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  const fetchList = useCallback(
    async (headersData, id) => {
      delete headersData.companyId;
      dispatch({ type: "status", payload: "fetching" });

      const defaultHeadersData = {
        ...{
          pagesize: DEFAULT_PAGESIZE,
          pagenumber: 1,
        },
        ...headersData,
      };
      if (defaultHeadersData.isActive === -1)
        delete defaultHeadersData.isActive;

      const resData = await req(
        `Project/List/${id}`,
        null,
        {},
        true,
        defaultHeadersData
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_list", payload: resData.data.projects || [] });
      dispatch({ type: "set_count", payload: resData.data.totalSize });
    },
    [req]
  );

  const setFilters = useCallback((filters) => {
    dispatch({ type: "set_filters", payload: filters });
  }, []);

  const fetchOne = useCallback(
    (id) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });
        const resData = await req(`Project/Info/${id}`, null, {}, true);

        dispatch({ type: "set_selected", payload: resData.data });
        dispatch({ type: "status", payload: `idle` });
        resolve(resData.data);
      });
    },
    [req]
  );

  const create = useCallback(
    async (data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            "Project/Create",
            data,
            { method: "POST" },
            true
          );
          // dispatch({ type: "add", payload: resData.data });
          // dispatch({ type: "add_document", payload: resData.data.documents });
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const edit = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `Project/Update/${id}`,
            data,
            { method: "PUT" },
            true
          );
          dispatch({ type: "edit", payload: resData });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const remove = useCallback(
    async (id) => {
      dispatch({ type: "status", payload: `deleting ${id}` });
      await req(`Project/Delete/${id}`, {}, { method: "DELETE" }, true);
      dispatch({ type: "status", payload: "idle" });
      dispatch({ type: "delete", payload: id });
    },
    [req]
  );

  const _uploadImage = useCallback(
    (data) => {
      let formData = new FormData();
      formData.append("file", data);
      formData.append("uploadType", 1);
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            "Upload/Misc",
            formData,
            { method: "POST", headers: {} },
            true
          );
          dispatch({ type: "set_fileName", payload: resData.data.fileName });
          resolve(resData.data);
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );

  /**upload logo */
  const _uploadLogo = useCallback(
    (data, id) => {
      let formData = new FormData();
      formData.append("file", data);
      formData.append("id", id);
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            "Upload/Misc",
            formData,
            { method: "POST", headers: {} },
            true
          );
          dispatch({ type: "set_logo", payload: resData.data.fileName });
          resolve(resData.data);
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );
  /** set tag */
  const setTag = useCallback(
    async (id, tid, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `Project/SetTag/${id}, ${tid}`,
            data,
            { method: "PUT" },
            true
          );
          dispatch({ type: "edit", payload: resData });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  /** set link */
  const setLink = useCallback(
    async (id, tid, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `Project/SetLink/${id}, ${tid}`,
            data,
            { method: "PUT" },
            true
          );
          dispatch({ type: "edit", payload: resData });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  /** set file */
  const setFile = useCallback(
    async (id, tid, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `Project/SetFile/${id}, ${tid}`,
            data,
            { method: "PUT" },
            true
          );
          dispatch({ type: "edit", payload: resData });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  return (
    <Context.Provider
      value={{
        state,
        fetchList,
        dispatch,
        create,
        fetchOne,
        edit,
        remove,
        setFilters,
        _uploadImage,
        _uploadLogo,
        setTag,
        setLink,
        setFile,
      }}
    >
      {children}
    </Context.Provider>
  );
}
