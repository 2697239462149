import React, { useEffect, useState } from "react";
import FileField from "../../../../ui/FileField";
import { Button, Flex } from "../../../../ui";
import { useNews } from "../../../news/provider";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useProject } from "../../provider";
import { useParams } from "react-router-dom";
import ErrorsList from "../../../../ui/ErrorsList";

const formSchema = Yup.object().shape({
  fileName: Yup.string().required("this field is required"),
});

const UploadImage = ({ open, setOpen }) => {
  const [preview2, setPreview2] = useState(null);
  const [Error, setError] = useState();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const {
    state: { fileName },
    // dispatch,
    _uploadImage,
  } = useNews();

  const { setFile, fetchOne } = useProject();
  const handleLogoChange = (event) => {
    setPreview2(URL.createObjectURL(event.target.files[0]));
    _uploadImage(event.target.files[0]);
  };
  useEffect(() => {
    setValue("fileName", fileName);
  }, [fileName]);
  const onSubmit = async (values) => {
    try {
      await setFile(id, 0, values);
      fetchOne(id);
      setOpen(!open);
    } catch (error) {
      setError(error.errors.message);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {Error && <ErrorsList error={Error} />}
      <div style={{ padding: 10 }}>
        <FileField
          id="logo"
          label="Upload Image"
          buttonLabel={"Upload Image"}
          preview={preview2}
          style={{ margin: "15px 5px" }}
          onChange={(e) => {
            handleLogoChange(e);
          }}
          inputProps={{
            name: "image2",
            // ...register000("image"),
            accept: "image/jpng,image/png,image/jpg,image/jpeg",
          }}
        />
        <Flex jc="center">
          <Button
            iconName="Disk"
            className="small"
            style={{ marginRight: 5 }}
            type="submit"
          >
            Save
          </Button>
          <Button
            type="button"
            className="outlined small"
            onClick={() => {
              setOpen(!open);
            }}
          >
            Cancel
          </Button>
        </Flex>
      </div>
    </form>
  );
};

export default UploadImage;
