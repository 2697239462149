import React, { useState } from "react";
import { useUtils } from "../../../../services/utilsProvider";
import FormLink from "./FormLink";

const UpdateLink = ({ link, open, setOpen }) => {
  const { notify } = useUtils();

  const handleSuccess = () => {
    notify("A link has been Updated successfully.");
    setOpen(!open);
  };
  return (
    <>
      <h3 style={{ marginTop: "0" }}>Update Link </h3>
      <FormLink
        link={link}
        open={open}
        setOpen={setOpen}
        handleSuccess={handleSuccess}
      />
    </>
  );
};

export default UpdateLink;
