import { LinkType } from "../../../../data/Enums";

const fields = [
  {
    label: "Type",
    name: "typeId",
    select: true,
    resourceName: "typeList",
    enum: "typeId",
    data: LinkType,
    mapData: { value: "id", label: "type" },
    validationType: "number",
    validations: [
      {
        type: "nullable",
        params: [true],
      },
    ],
  },
  { label: "URL", name: "url", type: "text" },

  { label: "Caption", name: "caption", type: "textarea" },
];

export default fields;
