import React, { useEffect, useState } from "react";
import ErrorsList from "../../../../ui/ErrorsList";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import fields from "./fields";
import { createYupSchema } from "../../../../utilities/yupSchemaCreator";
import { Box, Button, Flex, TextField } from "../../../../ui";
import Label from "../../../../ui/InputLabel";
import Editor from "../../../news/Editor";
import { useProject } from "../../provider";
import { useParams } from "react-router-dom";

const yupSchema = fields.reduce(createYupSchema, {
  tag: Yup.string().required("Required"),
  businessContext: Yup.string(),
  valueDelivered: Yup.string(),
});
const schema = Yup.object().shape(yupSchema);

const FormTag = ({ tag, handleSuccess, open, setOpen }) => {
  const [error, setError] = useState(null);
  const [editorBusinessContext, setEditorBusinessContext] = useState(
    tag?.businessContext
  );
  const [editorValueDelivered, setEditorValueDelivered] = useState(
    tag?.valueDelivered
  );
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { setTag, fetchOne } = useProject();

  useEffect(() => {
    setValue("businessContext", editorBusinessContext);
  }, [editorBusinessContext]);

  useEffect(() => {
    setValue("valueDelivered", editorValueDelivered);
  }, [editorValueDelivered]);
  const onSubmit = async (values) => {
    try {
      if (tag) {
        await setTag(id, tag?.id, values);
      } else {
        await setTag(id, 0, values);
      }
      fetchOne(id);
      handleSuccess();
    } catch (error) {
      setError(error.errors.message);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && <ErrorsList error={error} />}
      {fields.map((f, i) => (
        <>
          {f.type === "text" ? (
            <TextField
              key={i}
              label={f.label}
              name={f.name}
              error={!!errors[f.name]}
              msg={errors[f.name]?.message}
              style={{ width: "700px", margin: "5px" }}
              inputProps={{
                ...register(f.name),
                defaultValue: tag?.tag,
              }}
            />
          ) : (
            <>
              <div style={{ width: "700px", margin: "15px 5px" }}>
                <Label>{f.label}</Label>
                <Editor
                  editorHtml={
                    f.name === "businessContext"
                      ? editorBusinessContext
                      : editorValueDelivered
                  }
                  setEditorHtml={
                    f.name === "businessContext"
                      ? setEditorBusinessContext
                      : setEditorValueDelivered
                  }
                  defaultValue={
                    f.name === "businessContext"
                      ? editorBusinessContext
                      : editorValueDelivered
                  }
                />
              </div>
            </>
          )}
        </>
      ))}
      <Flex jc="end" sx={{ m: 5 }}>
        <Button
          iconName="Disk"
          className="small"
          style={{ marginRight: 5 }}
          type="submit"
        >
          Save
        </Button>
        <Button
          type="button"
          className="outlined small"
          onClick={() => {
            setOpen(!open);
          }}
        >
          Cancel
        </Button>
      </Flex>
    </form>
  );
};

export default FormTag;
