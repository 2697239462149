import months from "../data/months.json";
import countries from "../data/countries.json";
import XLSX from "sheetjs-style";
import FileSaver from "file-saver";

/**
 * firstUp
 * capitalize first char in a string
 * @param  {string} s any string to capitalize
 * @return {string}   the capitalize string
 */

const firstUp = (s) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

/**
 * toDateTime
 * convert a utc date to formatted date string
 * @param  {string} dateString utc date string to convert
 * @param  {object} options take two optional options {time , short}
 *                          1- {boolean} time : true to return formatted date time ,false to return formatted date without time
 *                          2- {boolean} short : true to return month as a full month name ,false to return month name as a symbol
 * @return {string}   the formatted date string
 */

function toDateTime(dateString, options = { time: true, short: false }) {
  const objectDate = new Date(dateString);

  if (!objectDate) return;
  let day = objectDate.getDate();
  let month = objectDate.getMonth();
  let year = objectDate.getFullYear();

  if (isNaN(month)) {
    return;
  }
  const date = `${
    months[month][options.short ? "short" : "long"]
  } ${day}, ${year}`;

  if (!options?.time) return date;

  let hours = objectDate.getHours();
  let minutes = objectDate.getMinutes();

  let ampm = hours < 12 ? "AM" : "PM";

  return `${date}, ${hours === 12 ? hours : ("0" + (hours % 12)).slice(-2)}:${(
    "0" + minutes
  ).slice(-2)} ${ampm}`;
}

/**
 * objByStr
 * extract value from nested object
 * @param  {object} o the object to extract value from
 * @param  {string} s the value to extract as a string example : employee.info.fullname
 * @return {any}      needed value
 */

function objByStr(o, s) {
  if (!o) return;
  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  var a = s.split(".");
  for (var i = 0, n = a.length; i < n; ++i) {
    if (!o) return;
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}

/**
 * rmEmpty
 * deleting the empty value from the passed object
 * @param  {object} obj the object that needs to be removed empty value
 * @return {object}     object without empty values
 */
function rmEmpty(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}

/**
 * rmValueFromObj
 * deleting the specific values from the passed object
 * @param  {object} obj                 the object that needs to be removed empty value
 * @param  {array of string} valuesList array of strings containing the unwanted values
 * @return {object}                     object without unwanted values
 */

function rmValueFromObj(obj, valuesList) {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => !valuesList.includes(v))
  );
}

/**
 * rmValueFromObj
 * deleting the specific values from the passed object
 * @param  {object} obj                 the object that needs to be removed empty value
 * @param  {array of string} valuesList array containing the unwanted values examples [0 , 'g' , null , undefined ]
 * @return {object}                     object without unwanted values
 */
function genYears(min = 1900, max = new Date().getFullYear()) {
  var years = [];

  for (var i = max; i >= min; i--) {
    years.push(i.toString());
  }
  return years;
}

/**
 * transformDate
 * converts a date to a utc date to a formatted string
 * @param  {string} date    date to convert
 * @param  {boolean} time   if true convert to utc dateTime else convert to utc
 * @return {object}         string representation of the date
 */
function transformDate(date, time = false) {
  if (time) {
    var tzoffset = new Date().getTimezoneOffset() * 60000;
    //console.log((new Date(date.getTime() - tzoffset)).toISOString().replace("T", " ").replace(":00.000Z", "") , 'tzoffset')
    // var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
    return new Date(date.getTime() - tzoffset)
      .toISOString()
      .replace("T", " ")
      .replace(":00.000Z", "");
  }
  return date.toISOString().split("T")[0];
}

/**
 * subtractYears
 * get the year before {x} year
 * @param  {number} numOfYears    number of years to subtract
 * @param  {date} date            the date to subtract from, default date is now
 * @return {date}                 date after subtract the year
 */

function subtractYears(numOfYears, date = new Date()) {
  const dateCopy = new Date(date.getTime());

  dateCopy.setFullYear(dateCopy.getFullYear() - numOfYears);

  return dateCopy;
}

/**
 * addDays
 * get the date after {x} day
 * @param  {number} days          number of days to add
 * @param  {date} date            the date to add to ,default date is now
 * @return {date}                 date after add the days
 */

function addDays(days, date = new Date()) {
  const dateCopy = new Date(date.getTime());
  dateCopy.setDate(dateCopy.getDate() + days);

  return dateCopy;
}

/**
 * createThumb
 * create the thumb of image with given width and height
 * @param  {file} blobImage       number of days to add
 * @param  {number} w             width of the thumb
 * @param  {number} h             height of the thumb
 * @return {blob}                 the thumb
 */
const createThumb = (blobImage, w, h) => {
  return new Promise((resolve, reject) => {
    try {
      var reader = new FileReader();
      //File reader is for some reason asynchronous
      reader.onloadend = function () {
        var img = document.createElement("img");
        img.onload = async function (e) {
          resolve(await _resize(img, w, h, blobImage.type));
        };
        img.onerror = (e) => {
          console.log(e);
        };
        img.src = reader.result;
      };
      //This starts the conversion
      reader.readAsDataURL(blobImage);
    } catch (e) {
      console.error(e);
      reject(e.message);
    }
  });

  function _resize(img, w, h, type) {
    var width = img.width;
    var height = img.height;
    // var x = 0;
    // var y = 0;

    if (width < height) {
      height = height * (w / width);
      width = w;
      // x = 0;
      // y = -((height - h) / 2);
    } else {
      width = width * (h / height);
      height = h;
      // x = -((width - w) / 2);
      // y = 0;
    }

    var canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;

    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);

    return new Promise((resolve, reject) => {
      try {
        canvas.toBlob((blob) => {
          resolve(blob);
        }, type);
      } catch (e) {
        console.error(e);
        reject(e.message);
      }
    });
  }
};

/**
 * downloadFile
 * download file from the server
 * @param  {string} fileUrl       url of the file
 * @param  {string} filename      the desired filename
 * @return
 */

function downloadFile(fileUrl, filename) {
  var link = document.createElement("a");
  link.setAttribute("href", fileUrl);
  link.setAttribute("download", filename);

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  // delete link;
}

let getValueFromEnum = (value, valueEntry, dataenum, returnedValueEntry) => {
  return dataenum
    .filter((data) => data[valueEntry] === value)
    .map((data) => data[returnedValueEntry]);
};

///////////////////////////////////////////////////////////////////////////////////
function currency(v, currency) {
  return v + " " + (currency || "");
}

function getCountry(code) {
  return countries
    .filter((e) => e.code == code.toUpperCase())
    .map((e) => {
      return e.name;
    });
}

function isFixed(fixed) {
  return fixed ? "Fixed" : "not Fixed";
}

function isActive(active) {
  return active ? "Active" : "not Active";
}

function IsSafari() {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf("safari") != -1) {
    if (ua.indexOf("chrome") > -1) {
      return false; // Chrome
    } else {
      return true; // Safari
    }
  }
}

function convertUTCDateToLocalDate(date) {
  var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;
}

const getLocalDate = (x) => {
  let d = new Date(x).toLocaleString();
  let date = d.split(" ")[0];
  let time = d.split(" ")[1];
  let f = (
    date.split("/")[2] +
    "-" +
    date.split("/")[1] +
    "-" +
    date.split("/")[0] +
    " " +
    time.slice(0, 5)
  ).replace(",", "");
  let dd = new Date(f);

  let ddd = convertUTCDateToLocalDate(dd);
  return (
    ddd.getFullYear() +
    "-" +
    (ddd.getMonth() + 1).padLeft() +
    "-" +
    ddd.getDate().padLeft() +
    " " +
    ddd.getHours().padLeft() +
    ":" +
    ddd.getMinutes().padLeft()
  );
};

const normalgetLocalDate = (x) => {
  let d = new Date(x).toLocaleString();
  let dd = new Date(d);
  let ddd = convertUTCDateToLocalDate(dd);
  return (
    ddd.getFullYear() +
    "-" +
    (ddd.getMonth() + 1).padLeft() +
    "-" +
    ddd.getDate().padLeft() +
    " " +
    ddd.getHours().padLeft() +
    ":" +
    ddd.getMinutes().padLeft()
  );
};

function dateToLocalConverter(x) {
  if (IsSafari()) return getLocalDate(x);
  else return normalgetLocalDate(x);
}

const transformDate2 = (x) => {
  let d = new Date(x).toLocaleString();
  let dd = new Date(d);
  let ddd = convertUTCDateToLocalDate(dd);

  return (
    ddd.getFullYear() +
    "-" +
    (ddd.getMonth() + 1).padLeft() +
    "-" +
    ddd.getDate().padLeft() +
    " " +
    ddd.getHours().padLeft() +
    ":" +
    ddd.getMinutes().padLeft()
  );
};

const setDateFormat = (x, full) => {
  if (!x) return;
  let d = new Date(x);
  console.log(full, "setDateFormat");
  if (full)
    return (
      d.getFullYear() +
      "-" +
      (d.getMonth() + 1).padLeft() +
      "-" +
      d.getDate().padLeft() +
      " " +
      d.getHours().padLeft() +
      ":" +
      d.getMinutes().padLeft()
    );
  else
    return (
      d.getFullYear() +
      "-" +
      (d.getMonth() + 1).padLeft() +
      "-" +
      d.getDate().padLeft()
    );
};

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtention = ".xlsx";

const exporToExcle = async (exdata, name) => {
  const ws = XLSX.utils.json_to_sheet(exdata);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, name + fileExtention);
};

const turncate = (s, len = 40) => {
  if (!s || s.length <= len) return s;
  //trim the string to the maximum length
  var trimmedString = s.substr(0, len);

  //re-trim if we are in the middle of a word
  let trimmed = trimmedString.substr(
    0,
    Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
  );
  return trimmed + "...";
};

function delay(callback, ms = 500) {
  var timer = 0;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(function () {
      callback.apply(this, args);
    }, ms || 0);
  };
}
const getUtc = (x) => {
  let d = new Date(x);
  return (
    d.getUTCFullYear() +
    "-" +
    (d.getUTCMonth() + 1).padLeft() +
    "-" +
    d.getUTCDate().padLeft() +
    " " +
    d.getUTCHours().padLeft() +
    ":" +
    d.getUTCMinutes().padLeft()
  );
};

const getLinkType = (typeId) => {
  var linkTypeText;
  switch (typeId) {
    case 1:
      linkTypeText = "AppStore";
      break;
    case 2:
      linkTypeText = "PlayStore";
      break;
    case 3:
      linkTypeText = "DownloadFile";
      break;
    case 4:
      linkTypeText = "Website";
      break;

    default:
      linkTypeText = "";
      break;
  }
  return linkTypeText;
};

function tolocalDateTime(date) {
  var d = new Date(date);
  let day = d.getDate();
  let month = d.getMonth() + 1;
  let year = d.getFullYear();
  let hour = d.getHours();
  let min = d.getMinutes();
  let sec = d.getSeconds();
  if (day < 10) day = "0" + day;
  if (month < 10) month = "0" + month;
  if (hour < 10) hour = "0" + hour;
  if (min < 10) min = "0" + min;
  var utc =
    year +
    "-" +
    month +
    "-" +
    day +
    "T" +
    hour +
    ":" +
    min +
    ":" +
    "00.000" +
    "Z";
  var local = new Date(utc).toLocaleString("en-GB", { hour12: true });

  return local;
}
export {
  firstUp,
  toDateTime,
  objByStr,
  rmEmpty,
  genYears,
  createThumb,
  downloadFile,
  currency,
  transformDate,
  subtractYears,
  addDays,
  getCountry,
  isFixed,
  isActive,
  dateToLocalConverter,
  transformDate2,
  setDateFormat,
  exporToExcle,
  rmValueFromObj,
  turncate,
  getValueFromEnum,
  delay,
  getLocalDate,
  getUtc,
  getLinkType,
  tolocalDateTime,
};
