import React, { useState } from "react";
import { Box, Button, Card, Flex, Grid, Icon } from "../../../../ui";
import Label from "../../../../ui/InputLabel";
import { IconButton } from "@mui/material";
import styled from "styled-components";
import { useUtils } from "../../../../services/utilsProvider";
import Modal from "../../../../ui/Modal";

import AddLink from "./AddLink";
import UpdateLink from "./UpdateLink";
import { useProject } from "../../provider";
import { useParams } from "react-router-dom";
import { getLinkType } from "../../../../utilities/functions";
import ErrorsList from "../../../../ui/ErrorsList";

const ButtonWrapper = styled.div`
  display: flex;
  .remove {
    &:hover {
      svg {
        fill: ${(props) => props.theme.color.red};
      }
    }
  }
`;

const Links = ({ links }) => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const { notify } = useUtils();

  const [LinkItem, setLinkItem] = useState();
  const { id } = useParams();

  const { setLink, fetchOne } = useProject();
  const [error, setError] = useState(null);

  const handleDeleteLink = async (link) => {
    try {
      await setLink(id, link?.id, {
        caption: "",
        url: "",
        typeId: link?.typeId,
      });
      fetchOne(id);
      notify("Tag has been deleted successfully.");
    } catch (error) {
      setError(error.errors.message);
    }
  };

  const handleEdit = (link) => {
    setShowEdit(!showEdit);
    setLinkItem(link);
  };
  return (
    <div>
      <Flex jc="start">
        <h3>Project Links</h3>

        <IconButton
          onClick={() => {
            setShowAdd(!showAdd);
          }}
        >
          <Icon name="addSquare" w={24} className="add" />
        </IconButton>
      </Flex>
      <Card>
        {Error && <ErrorsList error={Error} />}

        <Grid cols="1fr 1fr 1fr">
          <Label>Type</Label>
          <Label>Caption</Label>
          <Label>URL</Label>
          {links?.map((link, index) => (
            <React.Fragment key={index}>
              <p>{getLinkType(link?.typeId)}</p>
              <p>{link.caption}</p>
              <Flex jc="space-between">
                <p>{link?.url}</p>
                <ButtonWrapper>
                  <IconButton
                    onClick={() => {
                      handleEdit(link);
                    }}
                  >
                    <Icon w={13} h={13} name="pen" />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      if (window.confirm("Are you sure?"))
                        handleDeleteLink(link);
                    }}
                  >
                    <Icon className="remove" name="trash" w={16} />
                  </IconButton>
                </ButtonWrapper>
              </Flex>
            </React.Fragment>
          ))}
        </Grid>
      </Card>
      {showAdd && (
        <Modal
          isOpen={showAdd}
          onClose={() => {
            setShowAdd(!showAdd);
          }}
        >
          <AddLink open={showAdd} setOpen={setShowAdd} />
        </Modal>
      )}

      {showEdit && (
        <Modal
          isOpen={showEdit}
          onClose={() => {
            setShowEdit(!showEdit);
          }}
        >
          <UpdateLink link={LinkItem} open={showEdit} setOpen={setShowEdit} />
          {/**About Section */}
        </Modal>
      )}
    </div>
  );
};

export default Links;
