import React, { useState } from "react";
import { Card, Flex, Grid, Icon } from "../../../../ui";
import styled from "styled-components";

import UploadImage from "./UploadImage";
import Modal from "../../../../ui/Modal";
import { IconButton } from "@mui/material";
import { useProject } from "../../provider";
import { useUtils } from "../../../../services/utilsProvider";
import { useParams } from "react-router-dom";

const ImageContainer = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 12px;
  background-color: #f0f0f0;

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    align-content: center;
    object-position: center;
    transition: transform ease-in-out 0.5s;
    border-radius: 12px;
    cursor: zoom-in;
    &:hover {
      transform: scale(1.1);
    }
  }
  .fullPage {
    display: flex;
    position: absolute;
    z-index: 9999;
    top: -400px;
    left: 200px;
    width: 50vw;
    height: 50vh;
    border-radius: 12px;
    background-size: contain;
    background-repeat: no-repeat no-repeat;
    background-position: center center;
    background-color: black;
    cursor: zoom-out;
  }
`;

const Overlay = styled.div`
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${(props) => (props.fullScreen ? "block" : "none")};
  z-index: 1000;
`;
const Wrapper = styled.div`
  .remove {
    &:hover {
      svg {
        fill: ${(props) => props.theme.color.red};
      }
    }
  }
`;
const ImagesComponent = ({ Images }) => {
  const { id } = useParams();
  const [showAdd, setShowAdd] = useState(false);
  const [fullScreen, setFullScreen] = useState();
  const { notify } = useUtils();

  const { fetchOne, setFile } = useProject();
  const [selectedImage, setSelectedImage] = useState();
  const handleDeleteImage = async (ImageId) => {
    await setFile(id, ImageId, { fileName: "" });
    fetchOne(id);
    notify("Image has been deleted successfully.");
  };
  return (
    <div>
      <Flex jc="start">
        <h4>Project Images</h4>
        <IconButton
          onClick={() => {
            setShowAdd(!showAdd);
          }}
        >
          <Icon name="addSquare" w={24} className="add" />
        </IconButton>
      </Flex>
      <Card>
        <Grid cols="1fr 1fr 1fr 1fr 1fr 1fr">
          {Images?.map((image, index) => (
            <React.Fragment key={index}>
              {image?.fileUrl && (
                <Wrapper>
                  <ImageContainer
                    onClick={() => {
                      setFullScreen(!fullScreen);
                      setSelectedImage(image?.fileUrl);
                    }}
                  >
                    <Overlay fullScreen={fullScreen} />
                    <img
                      className={fullScreen ? "fullPage" : "image"}
                      src={fullScreen ? selectedImage : image?.fileUrl}
                    />
                  </ImageContainer>
                  <IconButton
                    className="remove"
                    onClick={() => {
                      if (window.confirm("Are you sure?"))
                        handleDeleteImage(image?.id);
                    }}
                  >
                    <Icon className="remove" name="trash" w={16} />
                  </IconButton>
                </Wrapper>
              )}
            </React.Fragment>
          ))}
        </Grid>
      </Card>
      {showAdd && (
        <Modal
          isOpen={showAdd}
          onClose={() => {
            setShowAdd(!showAdd);
          }}
        >
          <UploadImage open={showAdd} setOpen={setShowAdd} />
          {/**About Section */}
        </Modal>
      )}
    </div>
  );
};

export default ImagesComponent;
