import React from "react";
import styled from "styled-components";

const Overlay = styled.div`
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  z-index: 1000;
  display: ${(props) => (props.isOpen ? "" : "none")};
`;

const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 10px;
  width: fit-content;
  height: fit-content;
  border-radius: 8px;
  z-index: 1001;
  display: ${(props) => (props.isOpen ? "inline-block" : "none")};
`;

const Modal = ({ isOpen, onClose, children }) => {
  return (
    <>
      <Overlay isOpen={isOpen} onClick={onClose} />
      <Container isOpen={isOpen}>{children}</Container>
    </>
  );
};

export default Modal;
