import React, { useState } from "react";
import { Button, Card, Flex, Grid, Icon } from "../../../../ui";
import Label from "../../../../ui/InputLabel";
import { IconButton } from "@mui/material";
import styled from "styled-components";
import Editor from "../../../news/Editor";
import Modal from "../../../../ui/Modal";
import AddTag from "./AddTag";
import UpdateTag from "./UpdateTag";
import { useUtils } from "../../../../services/utilsProvider";
import { useParams } from "react-router-dom";
import { useProject } from "../../provider";

const ButtonWrapper = styled.div`
  display: flex;
  .remove {
    &:hover {
      svg {
        fill: ${(props) => props.theme.color.red};
      }
    }
  }
`;
const TagsComponent = ({ tags, viewOnly }) => {
  const [tagItem, setTagItem] = useState();
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const { notify } = useUtils();

  const { id } = useParams();

  const { setTag, fetchOne } = useProject();

  const handleDeleteTag = async (tagId) => {
    try {
      await setTag(id, tagId, {
        tag: "",
        businessContext: "",
        valueDelivered: "",
      });
      fetchOne(id);
      notify("Tag has been deleted successfully.");
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleEdit = (tag) => {
    setShowEdit(!showEdit);
    setTagItem(tag);
  };
  return (
    <div>
      <Flex jc="start">
        <h3>Project Tags</h3>
        <IconButton
          onClick={() => {
            setShowAdd(!showAdd);
          }}
        >
          <Icon name="addSquare" w={24} className="add" />
        </IconButton>
      </Flex>
      <Card>
        <Grid cols="1fr 1fr 1fr">
          <Label>Tag</Label>
          <Label>Business Context</Label>
          <Label>Value Delivered</Label>

          {tags?.map((tag, index) => (
            <React.Fragment key={index}>
              <p>{tag?.tag}</p>
              <div>
                {/* <Editor
              editorHtml={editorHtml}
              setEditorHtml={setEditorHtml}
              readOnly={true}
            /> */}

                <div
                  dangerouslySetInnerHTML={{ __html: tag?.businessContext }}
                />
              </div>

              <Flex jc="space-between">
                {/* <Editor
              editorHtml={editorHtml2}
              setEditorHtml={setEditorHtml2}
              readOnly={true}
            /> */}

                <div
                  dangerouslySetInnerHTML={{ __html: tag?.valueDelivered }}
                />
                <ButtonWrapper>
                  <IconButton
                    onClick={() => {
                      handleEdit(tag);
                    }}
                  >
                    <Icon w={13} h={13} name="pen" />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      if (window.confirm("Are you sure?"))
                        handleDeleteTag(tag.id);
                    }}
                    type="button"
                  >
                    <Icon className="remove" name="trash" w={16} />
                  </IconButton>
                </ButtonWrapper>
              </Flex>
            </React.Fragment>
          ))}
        </Grid>
      </Card>
      {showAdd && (
        <Modal
          isOpen={showAdd}
          onClose={() => {
            setShowAdd(!showAdd);
          }}
        >
          <AddTag open={showAdd} setOpen={setShowAdd} />
        </Modal>
      )}

      {showEdit && (
        <Modal
          isOpen={showEdit}
          onClose={() => {
            setShowEdit(!showEdit);
          }}
        >
          <UpdateTag tag={tagItem} open={showEdit} setOpen={setShowEdit} />
        </Modal>
      )}
    </div>
  );
};

export default TagsComponent;
