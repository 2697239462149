import React from "react";

// Importing core components
import QuillEditor from "react-quill";
import "react-quill/dist/quill.snow.css";
const Editor = ({ editorHtml, setEditorHtml, readOnly, defaultValue }) => {
  const handleChange = (html) => {
    setEditorHtml(html);
  };

  return (
    <QuillEditor
      style={{
        border: "1px solid rgb(194, 194, 194)",
        borderRadius: "12px",
        width: "auto",
        height: "auto",
      }}
      theme="snow"
      value={editorHtml}
      defaultValue={defaultValue}
      onChange={handleChange}
      readOnly={readOnly}
    />
  );
};

export default Editor;
