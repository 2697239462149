import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";

const Context = createContext();

export function useEmployees() {
  return useContext(Context);
}

const DEFAULT_PAGESIZE = 150;

const initState = {
  list: [],
  filters: {
    pagesize: DEFAULT_PAGESIZE,
  },
  currency: [],
  reportList: [],
  selected: null,
  // departmentID: null,
  // companyID: null,
  // search: "",
  count: 0,
  page: 0,
  status: "idle",
  avatar: null,
  error: null,
  statusID: 1,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_filters":
      console.log("set_filters action.payload = ", action.payload);
      return { ...state, filters: { ...state.filters, ...action.payload } };
    case "set_reportList":
      return { ...state, reportList: [...action.payload] };
    case "set_currency":
      return { ...state, currency: [...action.payload] };
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "set_count":
      return { ...state, count: action.payload };
    case "add":
      const newList = [{ ...action.payload }, ...(state.list || [])];
      return { ...state, list: newList };
    case "edit":
      const modified = (state.list || []).map((p) =>
        p.id === action.payload.id ? { ...p, ...action.payload } : p
      );
      return { ...state, list: modified };
    case "delete":
      const filtered = (state.list || []).filter(
        (p) => p.id !== action.payload
      );
      return { ...state, list: filtered };
    case "status":
      return { ...state, status: action.payload };
    case "set_avatar":
      return { ...state, avatar: action.payload };
    case "set_page":
      return { ...state, page: action.payload };
    case "compnayIDchange":
      return { ...state, companyID: action.payload };
    case "departmentIDchange":
      return { ...state, departmentID: action.payload };
    case "searchchange":
      return { ...state, search: action.payload };
    case "statusIDchange":
      return { ...state, statusID: action.payload };
    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function EmployeeProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  const fetchReportList = useCallback(
    async (headersData) => {
      dispatch({ type: "status", payload: "fetching" });
      if (!headersData.companyId) return;
      const defaultHeadersData = {
        ...{ pagesize: DEFAULT_PAGESIZE },
        ...headersData,
      };
      if (!defaultHeadersData.statusId) delete defaultHeadersData.statusId;

      const resData = await req(
        `Employee/Report`,
        null,
        {},
        true,
        defaultHeadersData
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_reportList", payload: resData.data.employees });
      dispatch({ type: "set_count", payload: resData.data.totalSize });
    },
    [req]
  );

  const fetchList = useCallback(
    async (headersData, statusID) => {
      dispatch({ type: "status", payload: "fetching" });

      const defaultHeadersData = {
        ...{
          pagesize: DEFAULT_PAGESIZE,
          statusId: statusID || 1,
          pagenumber: 1,
        },
        ...headersData,
      };

      if (
        !defaultHeadersData.departmentId ||
        defaultHeadersData.departmentId < 1
      )
        delete defaultHeadersData.departmentId;
      if (!defaultHeadersData.statusId) delete defaultHeadersData.statusId;

      const resData = await req(
        `Employee/List`,
        null,
        {},
        true,
        defaultHeadersData
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_list", payload: resData.data.employees });
      dispatch({ type: "set_count", payload: resData.data.totalSize });
    },
    [req]
  );

  const setFilters = useCallback((filters) => {
    dispatch({ type: "set_filters", payload: filters });
  }, []);

  const fetchCurrency = useCallback(async () => {
    dispatch({ type: "status", payload: "fetching" });
    const resData = await req(`Currency/List`, null, {}, true);
    dispatch({ type: "status", payload: `idle` });
    dispatch({ type: "set_currency", payload: resData.data });
    dispatch({ type: "set_count", payload: resData.data.totalSize });
  }, [req]);

  const fetchOne = useCallback(
    (id) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });
        const resData = await req(`Employee/Info/${id}/`, null, {}, true);
        dispatch({ type: "set_selected", payload: resData.data });
        dispatch({ type: "status", payload: `idle` });
        resolve(resData.data);
      });
    },
    [req]
  );

  const saveAvatar = useCallback(
    (file, id) => {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "status", payload: `loading` });
          // const thumb = await createThumb(file.logo[0], 500, 500);
          let formData = new FormData();
          formData.append("file", file.logo);
          //          formData.append("id", file.id);
          formData.append("id", file.id);

          const resData = await req(
            "Upload/EmployeePhoto",
            formData,
            { method: "POST", headers: {} },
            true
          );
          dispatch({ type: "status", payload: `idle` });
          resolve(resData, "save avatar");
        } catch (error) {
          dispatch({ type: "status", payload: `idle` });
          console.log(error);
          reject(error);
        }
      });
    },
    [req]
  );

  const setAvatar = useCallback(
    (file, id) => {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "status", payload: `loading` });
          // const thumb = await createThumb(file.logo[0], 500, 500);
          let formData = new FormData();
          formData.append("file", file.logo);
          //          formData.append("id", file.id);
          formData.append("id", file.id);

          const resData = await req(
            "Upload/Avatar",
            formData,
            { method: "POST", headers: {} },
            true
          );
          dispatch({ type: "set_avatar", payload: resData?.data.url });
          resolve(resData, "save avatar");
        } catch (error) {
          dispatch({ type: "status", payload: `idle` });
          console.log(error);
          reject(error);
        }
      });
    },
    [req]
  );

  const _uploadContract = useCallback(
    (data) => {
      let formData = new FormData();
      formData.append("file", data.contract[0]);

      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            "Upload/File",
            formData,
            { method: "POST", headers: {} },
            true
          );
          resolve(resData.data);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },
    [req]
  );

  const uploadDoc = useCallback(
    (data) => {
      let formData = new FormData();
      formData.append("file", data.cv[0]);
      formData.append("id", data.id);
      formData.append("title", data.title);

      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            "Upload/Document",
            formData,
            { method: "POST", headers: {} },
            true
          );
          resolve(resData.data);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },
    [req]
  );

  const importFile = useCallback(
    (id, data) => {
      let formData = new FormData();
      formData.append("file", data.cv[0]);
      formData.append("companyId", id);
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            "Employee/Import",
            formData,
            { method: "POST", headers: {} },
            true
          );
          resolve(resData.data);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },
    [req]
  );

  const create = useCallback(
    async (data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          let uploadRes;

          if (data.contractUpload?.length) {
            uploadRes = await _uploadContract({
              contract: data.contractUpload,
            });
            data.contract = uploadRes.fileName;
          } else {
            data.contract = "";
          }

          const newData = { ...data };
          delete newData.contractUpload;

          const resData = await req(
            "Employee/Create",
            newData,
            { method: "POST" },
            true
          );

          if (data?.avatar.length) {
            await saveAvatar({ id: resData.data.id, logo: data.avatar[0] });
          }

          dispatch({ type: "add", payload: resData.data });
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req, _uploadContract]
  );

  const edit = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          let uploadRes;
          if (data.contractUpload?.length) {
            uploadRes = await _uploadContract({
              contract: data.contractUpload,
            });
            data.contract = uploadRes.fileName;
          }
          if (data.avatar.length) {
            await saveAvatar({ id: id, logo: data.avatar[0] });
          }

          await req(`Employee/Update/${id}`, data, { method: "PUT" }, true);
          resolve({});
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req, _uploadContract]
  );
  /** About */
  const setAbout = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `Employee/SetAbout/${id}`,
            data,
            { method: "PUT" },
            true
          );
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  //change password
  const changepassword = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `changing password` });
        try {
          const resData = await req(
            `UserAccount/ResetPassword/${id}`,
            data,
            { method: "PUT" },
            true
          );
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const remove = useCallback(
    async (id) => {
      dispatch({ type: "status", payload: `deleting ${id}` });
      await req(`Employee/Delete/${id}`, {}, { method: "DELETE" }, true);
      dispatch({ type: "status", payload: "idle" });
      dispatch({ type: "delete", payload: id });
    },
    [req]
  );

  const deleteFile = useCallback(
    async (id) => {
      dispatch({ type: "status", payload: `deleting ${id}` });
      await req(
        `Employee/DeleteDocument/${id}`,
        {},
        { method: "DELETE" },
        true
      );
      dispatch({ type: "status", payload: "idle" });
      dispatch({ type: "delete", payload: id });
    },
    [req]
  );

  const setBank = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `EmployeeBank/Set/${id}`,
            data,
            { method: "PUT" },
            true
          );
          dispatch({ type: "edit", payload: resData });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const fetchBank = useCallback(
    (id) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });
        const resData = await req(`EmployeeBank/Info/${id}/`, null, {}, true);
        dispatch({ type: "set_selected", payload: resData.data });
        dispatch({ type: "status", payload: `idle` });
        resolve(resData.data);
      });
    },
    [req]
  );

  const removeBank = useCallback(
    async (id) => {
      dispatch({ type: "status", payload: `deleting ${id}` });
      await req(`EmployeeBank/Delete/${id}`, {}, { method: "DELETE" }, true);
      dispatch({ type: "status", payload: "idle" });
      dispatch({ type: "delete", payload: id });
    },
    [req]
  );

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        fetchList,
        fetchOne,
        create,
        edit,
        remove,
        changepassword,
        saveAvatar,
        importFile,
        setBank,
        fetchBank,
        removeBank,
        uploadDoc,
        deleteFile,
        fetchCurrency,
        fetchReportList,
        setAvatar,
        setFilters,
        setAbout,
      }}
    >
      {children}
    </Context.Provider>
  );
}
