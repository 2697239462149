import { Route } from "react-router-dom";
import AddProject from "./AddProject";
import EditProject from "./EditProject";
import ProjectsList from "./ProjectsList";
import { RequireAuth } from "../../services/auth";
import Projects from "./Projects";
import ViewProject from "./ViewProject";

export default (
  <>
    <Route
      path="projects/company/:cid"
      element={
        <RequireAuth>
          <Projects />
        </RequireAuth>
      }
    >
      <Route
        index
        element={
          <RequireAuth>
            <ProjectsList />
          </RequireAuth>
        }
      />
      <Route
        path="new"
        element={
          <RequireAuth>
            <AddProject />
          </RequireAuth>
        }
      />

      <Route
        path="edit/:id"
        element={
          <RequireAuth>
            <EditProject />
          </RequireAuth>
        }
      />

      <Route
        path="view/:id"
        element={
          <RequireAuth>
            <ViewProject />
          </RequireAuth>
        }
      />
    </Route>
  </>
);
