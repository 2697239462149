import styled, { css } from "styled-components";
import Label from "./InputLabel";

const StyledTextArea = styled.textarea`
  outline: #c2c2c2 1px solid;
  background-color: white;
  border-radius: 12px;
  max-width: 100%;

  width: 100%;
  border: none;
  min-height: 64px;
  padding: 20px;
  font-family: "Cairo", sans-serif;
  font-size: 20px;
  font-weight: 600;
  resize: none;
  &:focus,
  &:active {
    outline: #7f32de 2px solid;
    background-color: white;
  }
  &:read-only,
  &:disabled {
    outline: ${(props) => props.theme.color.grayBg} 1px solid;
  }
`;

const Wrapper = styled.div`
  margin-bottom: 25px;
  ${(props) =>
    props.noSpace &&
    css`
      margin-bottom: 0;
    `}
  .inputWrapper {
    position: relative;
    > span {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translate(0, -50%);
      cursor: pointer;
      fill: ${(props) => props.theme.color.dark};
      line-height: 0;
    }
  }

  p.msg {
    color: ${(props) => props.theme.color.grayText};
    padding-left: 9px;
    margin-top: 5px;
    margin-bottom: 0;
  }
  &.error p.msg {
    color: ${(props) => props.theme.color.red};
  }
`;

function TextArea({
  label,
  value,
  onChange = () => {},
  defaultValue,
  inputProps,
  msg = null,
  error = false,
  icon = null,
  small,
  cols = "80",
  rows = "1",
  ...props
}) {
  return (
    <Wrapper {...props} className={`${error && "error"}`}>
      <Label htmlFor={label.replace(/\s/g, "-").toLowerCase()}>{label}</Label>
      <div className="inputWrapper">
        {icon && <span>{icon}</span>}
        <StyledTextArea
          id={label.replace(/\s/g, "-").toLowerCase()}
          {...inputProps}
          rows={rows}
          cols={cols}
          value={value}
          onChange={onChange}
          defaultValue={defaultValue}
        />
      </div>
      {msg && <p className="msg">{msg}</p>}
    </Wrapper>
  );
}

export default TextArea;
